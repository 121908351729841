import React, { useState, useEffect } from "react";
import uploadIcon from "../../assets/visaForm/uploadIcon.svg";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import "./section.scss";
import axios from "../api/axios";
import { Document, Page } from "react-pdf";
import errIcon from "../../assets/visaForm/uploadError.svg";
import { Notificationmodal } from "../helpers/modal/simplifiedmodal";

const renderTitle = (index) => {
  switch (index) {
    case 0:
      return (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#14290A",
            paddingBottom: "0.5rem",
          }}
        >
          Upload Data Page of International Passport
        </p>
      );

    default:
  }
};

export const FileDoc = (props) => {
  const [isToggle, setIstoggle] = useState(false);
  //   const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [pageNumber, setPageNumber] = useState({
    0: 1,
  });
  const [viewFile, setViewFile] = useState(false);
  // const [selectedFiles, setSelectedFiles] = useState([{}]);
  const [numPages, setNumPages] = useState({
    0: 0,
    // 1: 0,
  });
  const [pdfData, setPdfData] = useState([""]);
  const [myIndex, setMyIndex] = useState(0);

  const [submittedPdfData, setSubmittedPdfData] = useState([""]);
  const [myDict, setMyDict] = useState();

  useEffect(() => {
    if (Array.isArray(props.Forms)) {
      const newData = props.Forms?.map((data) => ({
        immigrationPassport: data.immigrationPassport,
        dataPageOfPassport: data.dataPageOfPassport,
      }));
      setMyDict(newData);

      setPdfData([
        newData[0]?.immigrationPassport ? newData[0]?.immigrationPassport : "",
        // newData[0]?.dataPageOfPassport ? newData[0]?.dataPageOfPassport : "",
      ]);

      setSubmittedPdfData([
        newData[0]?.immigrationPassport ? newData[0]?.immigrationPassport : "",
        // newData[0]?.dataPageOfPassport ? newData[0]?.dataPageOfPassport : "",
      ]);
    }
  }, [props.Forms]);

  const fileNames = pdfData.map((url) => url.split("/").pop());

  const [errorMsgs, setErrorMsgs] = useState([""]);

  const onFileLoad = (event, index) => {
    const file = event.target.files[0];

    // Check if file size exceeds the limit (1010 KB)
    if (file.size / 1024 > 10240) {
      // Reset selectedFiles[index] and pdfData[index] to empty state
      props.setSelectedFiles((prevSelectedFiles) => {
        const newSelectedFiles = [...prevSelectedFiles];
        newSelectedFiles[index] = {};
        return newSelectedFiles;
      });

      setPdfData((prevPdfData) => {
        const newPdfData = [...prevPdfData];
        newPdfData[index] = "";

        const newErrorMsgs = [...errorMsgs];
        newErrorMsgs[index] = "File size exceeds the limit (10 MB)";
        setErrorMsgs(newErrorMsgs);

        // Clear the error message after 4 seconds
        setTimeout(() => {
          newErrorMsgs[index] = "";
          setErrorMsgs(newErrorMsgs);
        }, 2000);

        return newPdfData;
      });

      // Display error message (you can modify this part based on your UI)
      // setErrorMsg('File size exceeds the limit (1010 KB)');
      console.error("File size exceeds the limit (1010 KB)");
      return;
    }
    const newSelectedFiles = [...props.selectedFiles];
    newSelectedFiles[index] = file;
    props.setSelectedFiles(newSelectedFiles);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const newPdfData = [...pdfData];
      newPdfData[index] = reader.result;
      setPdfData(newPdfData);

      const selectedFileObject = {
        [index === 0 ? "immigrationPassport" : "dataPageOfPassport"]: file,
      };
    };
  };

  const onDocumentLoadSuccess = ({ numPages }, index) => {
    setNumPages((prevNumPages) => ({
      ...prevNumPages,
      [index]: numPages,
    }));
  };

  const [progress, setProgress] = useState(0);
  const [uploadedDoc, setUploadedDoc] = useState(false);
  const [triggerwarning, setTriggerWarning] = useState(false);

  useEffect(() => {
    if (uploadedDoc) {
      const interval = setInterval(() => {
        setUploadedDoc(true);

        if (progress < 100) {
          setProgress((prevProgress) => prevProgress + 1);
        } else {
          clearInterval(interval);
          setUploadedDoc(false);

          setViewFile(true);

          setProgress(0);
        }
      }, 70);
      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [progress, uploadedDoc, props.loading]);

  const submitApplication = async () => {
    var url =
      "https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents-no-auth";

    const formData = new FormData();
    formData.append("immigrationPassport", props.selectedFiles[0]);
    formData.append("visaType", "H1B");

    // formData.append("email", props.state.email);

    formData.append("submitted", true);
    formData.append("hasWatchedPathwayVideo", true);

    formData.append(
      "hasCompanyFilling",
      props.state.recruitingCompany === "I have company filing for me"
        ? true
        : false
    );
    formData.append("dateOfBirth", props.state.dob);
    formData.append("firstName", props.state.firstName);
    formData.append("lastName", props.state.lastName);
    formData.append("email", props.state.email);
    formData.append(
      "fullname",
      `${props.state.firstName} ${props.state.lastName}`
    );

    formData.append(
      "hasBachelorsDegree",
      props.state.degree === "Yes" ? true : false
    );
    formData.append("middleName", props.state.middleName);
    formData.append(
      "companyFilling",
      props.state.recruitingCompany === "I have company filing for me"
        ? props.state.companyName
        : props.state.recruitingCompany
    );

    if (props.state.recruitingCompany === "I have company filing for me") {
      formData.append("companyFillingName", props.state.companyName);
      formData.append("companyFillingEIN", props.state.Ein);
      formData.append("companyFillingEmail", props.state.employerEmail);
      formData.append("companyFillingPhoneNumber", props.state.employerContact);
      formData.append("companyFillingAddress", props.state.EmployerAddress);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      props.setStep(2);
      localStorage.removeItem("mainStackPayment");
    } catch (error) {
      console.error("Error uploading file:", error);
      // dispatch(updateUploadedFiles({}));
    }
  };

  const Storedpayment = localStorage.getItem("mainStackPayment");

  return (
    <div>
      <Notificationmodal open={viewFile} onClick={() => setViewFile(false)}>
        <div>
          <Document file={pdfData[myIndex]}>
            <Page pageNumber={1} />
          </Document>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => {
                setPageNumber((prevPageNumber) => ({
                  ...prevPageNumber,
                  [myIndex]: Math.max(prevPageNumber[myIndex] - 1, 1),
                }));
              }}
              disabled={pageNumber[myIndex] <= 1}
              style={{
                color: "#67A948",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Back
            </button>

            <p>
              Page {pageNumber[myIndex]} of {numPages[myIndex]}
            </p>

            <button
              onClick={() => {
                setPageNumber((prevPageNumber) => ({
                  ...prevPageNumber,
                  [myIndex]: Math.min(
                    prevPageNumber[myIndex] + 1,
                    numPages[myIndex]
                  ),
                }));
              }}
              disabled={pageNumber[myIndex] >= numPages[myIndex]}
              style={{
                color: "#67A948",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Notificationmodal>
      <div className="" style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>
            {[0].map((index) =>
              pdfData[index] === "" ? (
                <div key={index}>
                  {renderTitle(index)}
                  <input
                    type="file"
                    id={`fileInput${index}`}
                    accept=".pdf"
                    onChange={(event) => {
                      onFileLoad(event, index);
                    }}
                    style={{
                      display: "none",
                    }}
                  />

                  <label
                    htmlFor={`fileInput${index}`}
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                    }}
                    className="visaUploadInputs"
                  >
                    <img
                      src={uploadIcon}
                      alt=""
                      style={{
                        margin: "0px auto",
                      }}
                    />
                    Click to upload
                    <p
                      style={{
                        color: "#14290A",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Upload PDF only (Max. File size: 10 MB)
                    </p>
                  </label>

                  {errorMsgs[index] ? (
                    <div className="visaFileLimitError">
                      {" "}
                      <img src={errIcon} alt="" /> {errorMsgs[index]}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>
                  <>{renderTitle(index)}</>
                  <div
                    className="visaFormSectionContainer__subsections"
                    key={index}
                  >
                    <div className="visaFormSectionContainer__subsectionsFlex">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <IoDocumentTextOutline />
                        <div>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#14290A",
                            }}
                          >
                            {props.selectedFiles[index]?.name
                              ? props.selectedFiles[index]?.name
                              : pdfData[index]?.split("/").pop()}
                          </p>
                          <>
                            {props.selectedFiles[index]?.size ? (
                              <p
                                style={{
                                  color: "#98A2B3",
                                  fontSize: "13px",
                                  margin: "12px 0px",
                                }}
                              >
                                {" "}
                                {Math.round(
                                  props.selectedFiles[index]?.size / 1024
                                )}{" "}
                                KB
                              </p>
                            ) : (
                              <div
                                style={{
                                  marginTop: "12px",
                                }}
                              >
                                <a
                                  href={`${pdfData[index]}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#67A948",
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                >
                                  Click to view
                                </a>
                              </div>
                            )}
                          </>
                        </div>
                      </div>

                      <>
                        {props.selectedFiles[index]?.name ? (
                          <RiDeleteBinLine
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setSelectedFiles((prevSelectedFiles) => {
                                const newSelectedFiles = [...prevSelectedFiles];
                                newSelectedFiles[index] = {};
                                return newSelectedFiles;
                              });
                              setPdfData((prevPdfData) => {
                                const newPdfData = [...prevPdfData];
                                newPdfData[index] = "";
                                return newPdfData;
                              });
                            }}
                          />
                        ) : (
                          <RiDeleteBinLine
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setMyIndex(index);

                              setTriggerWarning(true);
                            }}
                          />
                        )}
                      </>
                    </div>

                    {uploadedDoc && myIndex === index ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "4px",
                            marginBottom: "8px",
                            height: "7px",
                          }}
                        >
                          <div
                            style={{
                              width: `${progress}%`,
                              height: "100%",
                              backgroundColor: "#67A948",
                              borderRadius: "4px",
                              transition: "width 0.1s ease-in-out",
                            }}
                          />
                        </div>
                        <p
                          style={{
                            fontSize: "14px",
                          }}
                        >{`${progress}%`}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              )
            )}

            {props.isEmptyFieldErrCheck && !props.selectedFiles[0]?.size ? (
              <p className="text-red-400 font-semibold text-[12px]">
                * This field is not allowed to be empty
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            style={{
              margin: "1rem 0px 0.25rem 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div></div>
          </div>
        </div>
      </div>
      {/* step={}
      setStep={props.setStep} */}
      {/* {props.step == 2 || Storedpayment === "mainstack-payment-success" ? (
        <div className="flex justify-end">
          {props.isClarityVideoChecked &&
          props.selectedFiles[0]?.size &&
          props.state.firstName &&
          props.state.middleName &&
          props.state.lastName &&
          props.state.email &&
          props.state.dob &&
          props.state.degree &&
          props.state.recruitingCompany &&
          (props.state.recruitingCompany === "I have company filing for me"
            ? props.state.EmployerAddress
            : true) &&
          (props.state.recruitingCompany === "I have company filing for me"
            ? props.state.companyName
            : true) &&
          (props.state.recruitingCompany === "I have company filing for me"
            ? props.state.Ein
            : true) &&
          (props.state.recruitingCompany === "I have company filing for me"
            ? props.state.employerEmail
            : true) &&
          (props.state.recruitingCompany === "I have company filing for me"
            ? props.state.employerContact
            : true) ? (
            <p
              className="bg-vestigreen cursor-pointer px-14 py-3 rounded-md text-white text-[14px] font-[500] "
              onClick={() => {
                submitApplication();
              }}
            >
              Submit
            </p>
          ) : (
            <p className="bg-[#4D5366] cursor-not-allowed px-14 py-3 rounded-md text-white text-[14px] font-[500] ">
              Submit
            </p>
          )}
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};
