import React, { useState } from "react";
import Inputfloat from "../../inputs/inputfloat";
import { FileDoc } from "../fileDoc";
import covenLogo from "../../../assets/visaForm/covenlabsFullLogo.svg";
import AsraLogo from "../../../assets/visaForm/asraFullLogo.png";
import {
  BigIconOption,
  BigIconSingleValue,
  CustomizedSingleselect,
} from "../../helpers/singleselect";
import InfraNova from "../../../assets/H1bPetitionImg/infracomLogo.png";
import { useLocation } from "react-router-dom";

export const FormData = (props) => {
  // const [state, setState] = useState({
  //   fullName: "",
  //   email: "",
  //   dob: "",
  //   degree: "",
  //   recruitingCompany: "",
  //   companyName: "",
  //   Ein: "",
  //   EmployerAddress: "",
  //   employerEmail: "",
  //   employerContact: "",
  // });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recruiter = queryParams.get("recruiter");
  var setInput = (e) => {
    props.setState({ ...props.state, [e.target.name]: e.target.value });
  };

  var setDOBInput = (e) => {
    const { name, value } = e.target;
    // Remove any non-numeric characters
    const cleaned = value.replace(/\D/g, "");
    // Ensure the length is not more than 6 characters
    const truncated = cleaned.substring(0, 6);
    // Insert '/' after every 2 numbers
    const formatted = truncated
      .replace(/(\d{2})(\d{0,2})/, "$1/$2")
      .replace(/(\d{2})\/(\d{2})(\d{0,2})/, "$1/$2/$3");
    props.setState({ ...props.state, [name]: formatted });
  };

  var handleSelect = (name, value) => {
    props.setState({ ...props.state, [name]: value });
  };

  //  var handleSelect = value => {
  //    setState({ ...state, value });

  //  };

  const [option, setOption] = useState(null);
  const setMyOption = (value) => {
    setOption(value);
  };

  var selectOpt = [{ opt: "Yes" }, { opt: "No" }];

  var selectfillingCompany = [
    { opt: "Coven Works", icon: covenLogo },
    { opt: "Infra Nova", icon: InfraNova },
    { opt: "I have company filing for me", icon: "" },
  ];

  var selectfillingCompanywithRecruiter = [
    { opt: "Coven Works", icon: covenLogo },
    { opt: "Infra Nova", icon: InfraNova },
    { opt: "Rex Music", icon: covenLogo },
    { opt: "Laccer Inc", icon: InfraNova },
    { opt: "Wethrive Technologies Inc", icon: covenLogo },
    { opt: "I have company filing for me", icon: "" },
  ];

  // const [step, setStep] = useState(2);

  return (
    <div className="">
      <div>
        <p className="text-[#67A948]  font-[500] text-[18px]">
          Personal Information Section
        </p>
        <p className="text-[#2B5219] font-[600] text-[12px]">
          Fill in this form accurately with up to date information
        </p>
      </div>

      <div className="my-8 grid md:grid-cols-2 gap-4 ">
        <div>
          <Inputfloat
            type="text"
            name="fullName"
            label="Full Name (AS WRITTEN ON YOUR PASSPORT)"
            value={props.state.fullName}
            disabled={false}
            placeholder="Full Name"
            onChange={setInput}
          />
          {props.isEmptyFieldErrCheck && !props.state.fullName ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * This field is not allowed to be empty
            </p>
          ) : (
            <></>
          )}
        </div>

        <div>
          <Inputfloat
            type="text"
            name="email"
            label="Email"
            value={props.state.email}
            disabled={false}
            placeholder="Email"
            onChange={setInput}
          />
          {props.isEmptyFieldErrCheck && !props.state.email ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * This field is not allowed to be empty
            </p>
          ) : (
            <></>
          )}
        </div>

        <div>
          <Inputfloat
            type="text"
            name="dob"
            label="DATE OF BIRTH (MM/DD/YY)"
            value={props.state.dob}
            disabled={false}
            placeholder="MM/DD/YY"
            onChange={setDOBInput}
          />
          {props.isEmptyFieldErrCheck && !props.state.dob ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * This field is not allowed to be empty
            </p>
          ) : (
            <></>
          )}
        </div>

        <div>
          <CustomizedSingleselect
            value={props.state.degree}
            options={selectOpt.map((op, index) => ({
              key: index,
              label: op.opt,
              value: op.opt,
            }))}
            placeholder="Do you have a degree (Bachelors Degree/HND)"
            onChange={(selectedValue) => {
              handleSelect("degree", selectedValue);
            }}
            name="DO YOU HAVE A DEGREE (BACHELORS DEGREE/HND)"
          />

          {props.isEmptyFieldErrCheck && !props.state.degree ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * This field is not allowed to be empty
            </p>
          ) : (
            <></>
          )}
          {props.isEmptyFieldErrCheck && props.state.degree == "No" ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * A Bachelor Degree/HND is mandatory to complete registration and
              enter the lottery.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div>
          {recruiter ? (
            <CustomizedSingleselect
              value={
                props.state.recruitingCompany
                  ? props.state.recruitingCompany
                  : recruiter
              }
              options={selectfillingCompanywithRecruiter.map((op, index) => ({
                key: index,
                label: "",
                value: op.opt,
                image: op.icon,
              }))}
              placeholder={
                recruiter == "rex"
                  ? "Rex Music"
                  : recruiter == "laceer"
                  ? "Lacer Inc"
                  : recruiter == "wethrive"
                  ? "Wethrive Technologies Inc"
                  : recruiter
              }
              onChange={(selectedValue) => {
                handleSelect("recruitingCompany", selectedValue);
              }}
              name="CHOOSE RECRUITING COMPANY/EMPLOYER TO FILE FOR YOU"
              components={{
                SingleValue: BigIconSingleValue,
                Option: BigIconOption,
              }}
            />
          ) : (
            // <Inputfloat
            //   type="text"
            //   name="recruitingCompany"
            //   label="RECRUITING COMPANY/EMPLOYER TO FILE FOR YOU"
            //   value={recruiter}
            //   disabled={true}
            //   placeholder="Choose recruiting company"
            //   onChange={(selectedValue) => {
            //     handleSelect("recruitingCompany", recruiter);
            //   }}
            // />
            <CustomizedSingleselect
              value={props.state.recruitingCompany}
              options={selectfillingCompany.map((op, index) => ({
                key: index,
                label: "",
                value: op.opt,
                image: op.icon,
              }))}
              placeholder="Choose recruiting company"
              onChange={(selectedValue) => {
                handleSelect("recruitingCompany", selectedValue);
              }}
              name="CHOOSE RECRUITING COMPANY/EMPLOYER TO FILE FOR YOU"
              components={{
                SingleValue: BigIconSingleValue,
                Option: BigIconOption,
              }}
            />
          )}
          {props.isEmptyFieldErrCheck && props.state.recruitingCompany == "" ? (
            <p className="text-red-400 font-semibold text-[12px]">
              * This field is not allowed to be empty
            </p>
          ) : (
            <></>
          )}
        </div>

        {props.state.recruitingCompany === "I have company filing for me" ? (
          <>
            {" "}
            <div>
              <Inputfloat
                type="text"
                name="companyName"
                label="Company Name"
                value={props.state.companyName}
                disabled={false}
                placeholder="Company Name"
                onChange={setInput}
              />
              {props.isEmptyFieldErrCheck && props.state.companyName == "" ? (
                <p className="text-red-400 font-semibold text-[12px]">
                  * This field is not allowed to be empty
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Inputfloat
                type="text"
                name="Ein"
                label="EIN of filing company (Employer Identification Number)"
                value={props.state.Ein}
                disabled={false}
                placeholder="EIN of filing company"
                onChange={setInput}
              />
              {props.isEmptyFieldErrCheck && props.state.Ein == "" ? (
                <p className="text-red-400 font-semibold text-[12px]">
                  * This field is not allowed to be empty
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Inputfloat
                type="text"
                name="EmployerAddress"
                label="Address of Employer"
                value={props.state.EmployerAddress}
                disabled={false}
                placeholder="Address of Employer"
                onChange={setInput}
              />
              {props.isEmptyFieldErrCheck &&
              props.state.EmployerAddress == "" ? (
                <p className="text-red-400 font-semibold text-[12px]">
                  * This field is not allowed to be empty
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Inputfloat
                type="text"
                name="employerEmail"
                label="Employer contact person Email"
                value={props.state.employerEmail}
                disabled={false}
                placeholder="Employer contact person Email"
                onChange={setInput}
              />
              {props.isEmptyFieldErrCheck && props.state.employerEmail == "" ? (
                <p className="text-red-400 font-semibold text-[12px]">
                  * This field is not allowed to be empty
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Inputfloat
                type="text"
                name="employerContact"
                label="Employer contact person Phone number"
                value={props.state.employerContact}
                disabled={false}
                placeholder="Employer contact person Phone number"
                onChange={setInput}
              />
              {props.isEmptyFieldErrCheck &&
              props.state.employerContact == "" ? (
                <p className="text-red-400 font-semibold text-[12px]">
                  * This field is not allowed to be empty
                </p>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div>
        <FileDoc
          state={props.state}
          isClarityVideoChecked={props.isClarityVideoChecked}
          step={props.step}
          setStep={props.setStep}
          isEmptyFieldErrCheck={props.isEmptyFieldErrCheck}
          selectedFiles={props.selectedFiles}
          setSelectedFiles={props.setSelectedFiles}
        />
      </div>
    </div>
  );
};
